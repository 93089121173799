import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toggle-required"
export default class extends Controller {
  static targets = ["input"];

  toggle(event) {
    this.inputTargets.forEach((element) => {
      element.required = !element.required;
    });
  }
}
