import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["reasonInput", "dateInput"];

  static values = {
    lookup: Object,
  };

  setDate() {
    const reason = this.reasonInputTarget.value;
    const suggestedDate = this.lookupValue[reason === "" ? "" : reason];

    if (suggestedDate) {
      this.dateInputTarget.value = suggestedDate;
    } else {
      this.dateInputTarget.value = "";
    }
  }
}
