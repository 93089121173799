import { Controller } from "@hotwired/stimulus";
import SignaturePad from "signature_pad";
import * as trimCanvas from "trim-canvas";

export default class extends Controller {
  static targets = [
    "canvas",
    "input",
    "container",
    "postSubmitContainer",
    "errors",
    "placeholder",
  ];

  connect() {
    this.signaturePad = new SignaturePad(this.canvasTarget, {
      penColor: "#034E82",
    });

    this.signaturePad.addEventListener(
      "beginStroke",
      () => {
        this.placeholderTarget.classList.add("hidden");
      },
      { once: true },
    );

    window.onresize = this.resize.bind(this);

    this.resize();
  }

  disconnect() {
    this.signaturePad.off();
  }

  clear(event) {
    event?.preventDefault();

    this.signaturePad.clear();
  }

  save(event) {
    if (this.hasNoSignature() || this.hasInvalidSignature()) {
      this.errorsTarget.innerHTML = "Please sign with a larger signature.";
      this.signaturePad.clear();
      return event.preventDefault();
    }

    this.inputTarget.value = this.trimmedSignature().toDataURL("image/png");

    this.containerTarget.classList.toggle("e-sign__container--hidden", true);
    this.postSubmitContainerTarget.classList.toggle(
      "e-sign__post-submit-container--visible",
      true,
    );
  }

  resize() {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    const canvas = this.canvasTarget;

    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);

    this.clear();
  }

  hasNoSignature() {
    return this.signaturePad.isEmpty();
  }

  hasInvalidSignature() {
    const minimumSignatureDataSize = 4500;
    const imageData = this.signaturePad.toDataURL("image/png");

    return imageData.length < minimumSignatureDataSize;
  }

  onDialogOpen() {
    this.resize();
  }

  trimmedSignature() {
    const copy = document.createElement("canvas");
    copy.width = this.canvasTarget.width;
    copy.height = this.canvasTarget.height;
    copy.getContext("2d").drawImage(this.canvasTarget, 0, 0);
    trimCanvas.default(copy);
    return copy;
  }
}
